import { Injectable } from '@angular/core';
import {
  DataManagementService,
  Group,
  PoiClient,
  PointInterest,
  Vehicule,
} from '../../data-management';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { filesDir, imagesCarsDir, imagesDir } from 'src/app/global.config';
import { RealTimeRecord, StateCounts } from '../model/real-time.model';
import { RealtimeRestService } from './realtime-rest.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import Timer = NodeJS.Timer;
import { Icon, IconOptions, Marker } from 'leaflet';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { HeaderService } from 'src/app/header/header.service';
import { AlertManagementService } from '../../alert-management';
import Speech from 'speak-tts';
import { RealtimeHelperService } from './realtime-helper.service';
import { takeUntil, tap } from 'rxjs/operators';
import { DateInterval } from 'src/app/shared/model';

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  // Declaration vars !!!
  groupsDataSubject = new BehaviorSubject<Group[]>([]);
  regionDisplay = false;

  private destroy$ = new Subject<void>();
  selectedVehiculeSubjec: BehaviorSubject<Vehicule> =
    new BehaviorSubject<Vehicule>(new Vehicule());
  currentPathClickedSubjec: Subject<boolean> = new Subject<boolean>();
  barToolStatusSubjec: Subject<boolean> = new Subject<boolean>();

  imagesCarsDir: string = imagesCarsDir;
  imagesDir: string = imagesDir;
  filesDir = filesDir;
  url: string = imagesDir;
  liveTracking: Timer;
  liveReloadingRT: Timer;
  private isCurrentPathClicked = false;
  currentPathClickedDeviceId: number = null;
  selectedVehicule: Vehicule = new Vehicule();

  dateInteval : DateInterval;

  barToolStatus: boolean = false;

  searchedOption: BehaviorSubject<string> = new BehaviorSubject<string>('all');

  speech: any;
  speechData: any;

  isGroupLoaded = false;
  realTimeLoaded = false;
  trackingMode = false;
  endDate: Date= new Date();
  startDate: Date = new Date();

  workingGroups : Group[] = [];
  groups: Group[];
  vehicules: Vehicule[];

  newRealTimeRecords: RealTimeRecord[] = null;
  oldRealTimeRecords: RealTimeRecord[] = null;

  pointInterest: PointInterest = new PointInterest();
  poiClient: PoiClient = new PoiClient();

  angles: Map<number, number> = new Map();

  groupStateCountsSubject = new BehaviorSubject<Record<number, StateCounts>>(
    {}
  );
  // Subscription Http & Observable

  allRealTimeRecords: Subscription;
  allGroups: Subscription;
  allVehicules: Subscription;
  geocodingSubscription: Subscription;
  markerWasAddedSubscription: Subscription;
  allPathConfigs: Subscription;

  deviceChange = new Subject<void>();


  constructor(
    public realTimeRestService: RealtimeRestService,
    public mapService: MapService,
    public dataManagementService: DataManagementService,
    public geocodingService: GeocodingService,
    public headerService: HeaderService,
    public alertManagementService: AlertManagementService,
    public realtimeHelperService: RealtimeHelperService
  ) {}

  allRealTimeRecord() {
    clearInterval(this.liveReloadingRT);
    this.liveReloadingRT = setInterval(() => {
      this.getAllRealTimeRecords();
    }, 200000);

  }

  async getAllRealTimeRecords() {

    await this.getPoiInterest();

    if(this.dataManagementService.groups == null || this.dataManagementService.groups.length == 0){return}
    this.allRealTimeRecords = this.realTimeRestService
      .getAllRealTimeRecords()
      .pipe(
        tap((records) => {

          if(this.workingGroups.length == 0){
              this.setStatesCount(this.realtimeHelperService.calculateStateCounts(records, this.dataManagementService.getCurrentGroups()));
          }else{
              this.setStatesCount(this.realtimeHelperService.calculateStateCounts(records, this.workingGroups));
          }
        })
      )
      .subscribe((realTimeRecords) => {
        // Keep trace of oldRealTimeRecords !!!

        if (this.newRealTimeRecords) {
          this.oldRealTimeRecords = this.newRealTimeRecords;
        }
        this.newRealTimeRecords = realTimeRecords;

        realTimeRecords.forEach((realTimeRecord) => {
          let vehicule = this.realtimeHelperService.getVehicule(
            realTimeRecord.idRealTimeRecord,
            this.groups
          );
          if (vehicule) {
            let pathSpeed = this.pathMinSpeedByDeviceId(
              realTimeRecord.idRealTimeRecord
            );
            let oldRealTimeRecord = new RealTimeRecord();
            oldRealTimeRecord.geocoding = null;
            oldRealTimeRecord.geocodingDetails = null;

            /** init decalage horaire !*/

            this.trackRealTimeRecord(
              vehicule,
              realTimeRecord,
              oldRealTimeRecord,
              pathSpeed,
              this.isCurrentPathClicked,
              this.currentPathClickedDeviceId,
              false
            );

            // => RT Notification MANAGER
            if (this.headerService.realTimeNotification) {
              this.manageNotifications(realTimeRecord, vehicule, pathSpeed);
            }
          }
        });
        this.realTimeLoaded = true;
      });
  }

  manageNotifications(
    realTimeRecord: RealTimeRecord,
    vehicule: Vehicule,
    pathSpeed: number
  ) {
    if (this.oldRealTimeRecords) {
      this.oldRealTimeRecords.map((oldRealTimeRecord) => {
        if (
          oldRealTimeRecord.idRealTimeRecord === realTimeRecord.idRealTimeRecord
        ) {
          let displayName =
            this.dataManagementService.getVehiculeName(vehicule);
          if (this.headerService.realTimeNotificationSound) {
            if (
              oldRealTimeRecord.speed <= pathSpeed &&
              realTimeRecord.speed > pathSpeed
            ) {
              this.alertManagementService.toastrMessages.push({
                message: displayName,
                title: ' a démarré !',
                type: 'success',
              });
              this.startSpeaking(displayName + 'a démarré');
              //this.toastr.success(displayName + " a démarré !");
            }
            if (
              oldRealTimeRecord.speed > pathSpeed &&
              realTimeRecord.speed <= pathSpeed &&
              realTimeRecord.ignition === false
            ) {
              this.alertManagementService.toastrMessages.push({
                message: displayName,
                title: " s'est arrêté !",
                type: 'error',
              });
              this.startSpeaking(displayName + "s'est arrêté");
              //this.toastr.error(displayName + " s'est arrêté!");
            }
          }
        }
      });
    }
  }

  trackRealTimeRecord(
    vehicule: Vehicule,
    realTimeRecord: RealTimeRecord,
    oldRealTimeRecord: RealTimeRecord,
    pathSpeed: number,
    isCurrentPathClicked: boolean,
    currentPathClickedDeviceId: number,
    isHidden: boolean
  ) {
    // Declaration of vars !!!
    let angle = 0;

    let icon;
    let marker : any;
    let popup = '';

    //===================== TREATMENT BLOCK
    // get Relative position !!!
      if (
        realTimeRecord.speed <= pathSpeed &&
        oldRealTimeRecord.speed <= pathSpeed &&
        oldRealTimeRecord.relativePosition
      ) {
        realTimeRecord.relativePosition = oldRealTimeRecord.relativePosition;
      } else {
        realTimeRecord.relativePosition =
          this.dataManagementService.getRelativePosition(
            realTimeRecord.coordinate.lat,
            realTimeRecord.coordinate.lng
          );
      }

    // Recuperate angle (angle will be recuperate from the server !!!)

    // ****************************** init angle for AA *****************************
    if (realTimeRecord.type === 'AA') {
      angle = realTimeRecord.rotationAngle * 8;
    }

    // ****************************** init angle for gprmc *****************************
    if (this.oldRealTimeRecords && realTimeRecord.type === 'GPRMC') {
      oldRealTimeRecord = this.realtimeHelperService.getOldRealTimeRecord(
        realTimeRecord.idRealTimeRecord,
        this.oldRealTimeRecords
      );
      if (oldRealTimeRecord) {
        if (
          !this.realtimeHelperService.compareTwoCoordinate(
            oldRealTimeRecord.coordinate,
            realTimeRecord.coordinate
          )
        ) {
          angle =
            (Math.atan2(
              realTimeRecord.coordinate.lng - oldRealTimeRecord.coordinate.lng,
              realTimeRecord.coordinate.lat - oldRealTimeRecord.coordinate.lat
            ) *
              180) /
            Math.PI;
          this.angles.set(realTimeRecord.idRealTimeRecord, angle);
        } else {
          angle = this.angles.get(realTimeRecord.idRealTimeRecord);
        }
      }
    }

    // ****************************** init driver avatar *****************************
    if (vehicule.driver && vehicule.driver.avatarUri) {
      popup =
        '<img width="40px" height: "40px" class="img-circle" src="' +
        vehicule.driver.avatarUri +
        '"/>';
    }

    let subMark = '';

    if (vehicule.subMark) {
      subMark = '<br><b>Remorque:</b> ' + vehicule.subMark;
    }

    if (!vehicule.groupName) {
      vehicule.groupName = this.realtimeHelperService.getGroupName(
        vehicule.idDevice,
        this.groups
      );
    }

    // ******************************  init popup of the RT marker !!! ******************************
    popup += this.realtimeHelperService.popUp(
      this.dataManagementService.getDriverName(vehicule.driver),
      vehicule,
      subMark,
      realTimeRecord
    );

    // ******************************  init the RT marker !!! ******************************
    marker = new Marker(realTimeRecord.coordinate);

    marker.on('mouseover', () => {
      marker.openPopup();
    });

    // init the RT marker icon !!!
    if (!oldRealTimeRecord.speed) {
      oldRealTimeRecord.speed = 0;
    }

    // STOP State
    let iconPopup = this.realtimeHelperService.createIcon(
      angle,
      vehicule,
      realTimeRecord,
      oldRealTimeRecord,
      pathSpeed,
      imagesCarsDir,
      imagesDir,
      popup,
      this.groups
    );
    icon = iconPopup.icon;
    popup = iconPopup.popup;

    //================================== TREATMENT BLOCK
    if (
      // realTimeRecord.speed === 0 &&
      // oldRealTimeRecord.speed === 0 &&
      oldRealTimeRecord.geocoding &&
      oldRealTimeRecord.geocodingDetails &&
      realTimeRecord.coordinate.lat === oldRealTimeRecord.coordinate.lat &&
      realTimeRecord.coordinate.lng === oldRealTimeRecord.coordinate.lng
    ) {
      realTimeRecord.geocoding = oldRealTimeRecord.geocoding;
      realTimeRecord.geocodingDetails = oldRealTimeRecord.geocodingDetails;
      // update table !
      this.realtimeHelperService.updateSpecificGroups(
        realTimeRecord,
        this.groups
      );
    } else if (
      this.realtimeHelperService.isValidPoint(realTimeRecord.coordinate) &&
      oldRealTimeRecord.recordTime != realTimeRecord.recordTime
    ) {
      const adressNominatim = JSON.parse(realTimeRecord.address);

      const adress = {
        address: adressNominatim,
        display_name: this.createAbbreviatedDisplayName(adressNominatim),
      };
      let nominatimFix =
        this.geocodingService.proccessingNomitamAddress(adress);

      popup = popup + '<hr><b>' + nominatimFix + '</b><br>';
      if (realTimeRecord.relativePosition) {
        popup =
          popup +
          '<hr><center><span class="leaflet-pelias-layer-icon-container"><div class="leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point"></div></span><strong>' +
          realTimeRecord.relativePosition +
          '</strong></center>';
      }
      marker.bindPopup(popup);
      realTimeRecord.geocoding = this.realtimeHelperService.getGeocoding(
        adress.address
      );
      if (!realTimeRecord.geocoding || 0 === realTimeRecord.geocoding.length) {
        realTimeRecord.geocoding = nominatimFix;
      }
      realTimeRecord.geocodingDetails = adress.display_name;
      this.realtimeHelperService.updateSpecificGroups(
        realTimeRecord,
        this.groups
      );
      let displaycurrentPath = false;
      if (
        isCurrentPathClicked === true &&
        currentPathClickedDeviceId === vehicule.idDevice
      ) {
        displaycurrentPath = true;
      }
      marker.bindPopup(popup);

      this.mapService.updateRtMarker(
        {
          id: vehicule.idDevice,
          value: marker,
          icon: icon as Icon<IconOptions>,
          angle: 0,
        },
        displaycurrentPath,
        isHidden
      );
    } else {
      // update table !
      this.realtimeHelperService.updateSpecificGroups(
        realTimeRecord,
        this.groups
      );
    }
  }
  updateStateCounts(groups: Group[]){
    this.workingGroups = groups;
    this.dataManagementService.setGroups(groups);
    this.setStatesCount(this.realtimeHelperService.calculateStateCounts(this.newRealTimeRecords, groups));
  }
  async getPoiInterest() {
    if (!this.dataManagementService.pointInterests) {
      try {
        const pointInterests = await this.dataManagementService.getAllPointInterests().toPromise();
        this.dataManagementService.pointInterests = pointInterests;
      } catch (error) {
        console.error('Error fetching point interests:', error);
      }
    }
  }

  getDeviceChange() : Observable<void>{
    return this.deviceChange;
  }
  setChangeDevice(){
      return this.deviceChange.next();
  }

  createAbbreviatedDisplayName(address: any): string {
    if (address == null || address == undefined) return;
    const attributeMap = {
      h: 'house',
      road: 'rd',
      p: 'place',
      hm: 'hamlet',
      qr: 'quarter',
      vl: 'village',
      sb: 'suburb',
      am: 'amenity',
      id: 'industrial',
      ng: 'neighbourhood',
      cty: 'city',
      tn: 'town',
      mu: 'municipality',
      cot: 'county',
      sd: 'state_district',
      reg: 'region',
      pc: 'postcode',
      cntry: 'country',
    };
    let displayNameParts = [];
    for (let key in attributeMap) {
      if (address[key]) {
        displayNameParts.push(address[key]);
      }
    }
    displayNameParts.push('Maroc');
    address.country = 'Maroc';
    return displayNameParts.join(', ');
  }

  pathMinSpeedByDeviceId(deviceId: number): number {
    const pathConfig = this.realTimeRestService.pathConfigs.filter(
      (p) => p.deviceId == deviceId
    );
    if (pathConfig && pathConfig.length > 0) {
      return pathConfig[0].pathMinSpeed;
    }
    return 0;
  }

  startSpeaking(text: string) {
    if (!this.headerService.realTimeNotificationSound) return;

    this.speech
      .speak({
        text: text,
      })
      .then(() => {})
      .catch((e: any) => {
        console.error('An error occurred :', e);
      });
  }

  textToSpeech() {
    this.speech = new Speech();
    // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) {
      // returns a boolean
      this.speech
        .init({
          volume: 1,
          lang: 'fr-FR',
        })
        .then((data: any) => {
          // The "data" object contains the list of available voices and the voice synthesis params
          this.speechData = data;
        })
        .catch((e: any) => {
          console.error('An error occured while initializing : ', e);
        });
    }
  }

  timerTracking(idDevice: number) {
    this.liveTracking = setInterval(() => {
      this.newRealTimeRecords.forEach((realTimeRecord) => {
        if (realTimeRecord.idRealTimeRecord === idDevice) {
          this.mapService.map.setView(
            realTimeRecord.coordinate,
            this.mapService.map.getZoom()
          );
        }
      });
    }, 1000);
  }
  stopTimerTracking() {
    clearInterval(this.liveTracking);
    this.liveTracking = null;
  }

  isLiveTrackingActive() {
    return !!this.liveTracking;
  }

  getGroups(): Observable<Group[]> {
    return this.groupsDataSubject.asObservable();
  }
  setGroupsData(groups: Group[]) {
    this.groups = groups;
    this.groupsDataSubject.next(groups);
  }

  getVehicule(): Observable<Vehicule> {
    return this.selectedVehiculeSubjec.asObservable();
  }
  getVehiculeVlue(): Vehicule {
    return this.selectedVehiculeSubjec.value;
  }
  setVehicule(vehicule: Vehicule) {
    this.selectedVehicule = vehicule;
    this.selectedVehiculeSubjec.next(vehicule);
  }

  isCurentPathSelected(): Observable<boolean> {
    return this.currentPathClickedSubjec.asObservable();
  }
  setCurentPathSelected(isSelected: boolean) {
    this.isCurrentPathClicked = isSelected;
    this.currentPathClickedSubjec.next(isSelected);
  }

  searchedOptionObs(): Observable<string> {
    return this.searchedOption.asObservable();
  }
  setsearchedOption(searching: string) {
    this.searchedOption.next(searching);
  }

  isBarToolStatus(): Observable<boolean> {
    return this.barToolStatusSubjec.asObservable();
  }

  setBarToolStatus(barToolStatus: boolean) {
    this.barToolStatus = barToolStatus;
    this.barToolStatusSubjec.next(barToolStatus);
  }

  getStatesCount(): Observable<Record<number, StateCounts>> {
    return this.groupStateCountsSubject.asObservable();
  }

  setStatesCount(records: Record<number, StateCounts>) {
    this.groupStateCountsSubject.next(records);
  }

  stopRealTime() {
    this.mapService.clearPolylines();
    this.mapService.removeMarkersAndClearArrays();
    this.setCurentPathSelected(false);
    this.stopTimerTracking();
    this.trackingMode = false;
  }

  clearRealTime() {
    clearInterval(this.liveReloadingRT);
    this.mapService.stopExecution = true;
    this.destroy$.next();
  }

  clearTables() {
    this.newRealTimeRecords = null;
    this.oldRealTimeRecords = null;
  }

  resetService() {
    this.isCurrentPathClicked = false;
    this.currentPathClickedDeviceId = null;
    this.barToolStatus = false;
    this.isGroupLoaded = false;
    this.realTimeLoaded = false;
    this.trackingMode = false;
    this.groups = null;
    this.vehicules = null;
    this.newRealTimeRecords = null;
    this.oldRealTimeRecords = null;
    this.pointInterest = new PointInterest();
    this.poiClient = new PoiClient();
    this.angles = new Map();
    this.workingGroups = [];
  }
}
