/** LOCAL CONFIG */

//export var dns: string = "https://dev.rimtrack.com/ws_rimtrack_all/";
// export var dns: string = 'http://localhost:8080/';
// export var dns: string = 'https://preprod.rimtrack.com/ws_rimtrack_all/'; //prod
//export var dns: string = 'https://rimtrack.com/ws_rimtrack_all/'; //preprod

// export var imagesDir: string = 'https://dev.rimtrack.com/images/';
// export var imagesCarsDir: string = 'https://dev.rimtrack.com/images/cars/';
// export var imagesPOIDir: string = 'https://dev.rimtrack.com/images/poi/';
// export var filesDir: string = 'https://dev.rimtrack.com/';
// export var rapportsDir: string = 'https://rimtrack.com/rapports/';
// export var nominatim_dns: string = 'https://dev.rimtrack.com/nominatim';

/** DEV CONFIG 54.39.156.216 **/
/*
export var dns: string = "/ws_rimtrack_all/";
export var imagesDir: string = "/images/";
export var imagesCarsDir: string = "/images/cars/";
export var imagesPOIDir: string = "/images/poi/";
export var filesDir: string = "http://79.137.75.178/";
export var rapportsDir: string = "/rapports/";
export var nominatim_dns: string = "/nominatim";
*/

/** PROD CONFIG **/

export var dns: string = '/ws_rimtrack_all/';
export var imagesDir: string = '/images/';
export var imagesCarsDir: string = '/images/cars/';
export var imagesPOIDir: string = '/images/poi/';
export var filesDir: string = 'http://142.44.215.92/';
export var rapportsDir: string = '/rapports/';
export var nominatim_dns: string = '/nominatim';

// export var dns: string = '/ws_rimtrack_all/';
// export var imagesDir: string = '/images/';
// export var imagesCarsDir: string = '/images/cars/';
// export var imagesPOIDir: string = '/images/poi/';
// export var filesDir: string = 'http://142.44.215.92/';
// export var rapportsDir: string = '/rapports/';
// export var nominatim_dns: string = '/nominatim';

/** BELLA CONFIG **/
/*
export var dns: string = "/ws_rimtrack_all/";
export var imagesDir: string = "/images/";
export var imagesCarsDir: string = "/images/cars/";
export var imagesPOIDir: string = "/images/poi/";
export var filesDir: string = "http://196.92.1.188:8080/";
export var rapportsDir: string = "/rapports/";
export var nominatim_dns: string = "/nominatim";
*/

/** BELLA DEV CONFIG **/
/*
export var dns: string = "/ws_rimtrack_all_dev/";
export var imagesDir: string = "/images/";
export var imagesCarsDir: string = "/images/cars/";
export var imagesPOIDir: string = "/images/poi/";
export var filesDir: string = "";
export var rapportsDir: string = "/rapports/";
export var nominatim_dns: string = "/nominatim";
*/

/** SHARAD CONFIG **/
export var token_google = 'AIzaSyDDLVspgdOaKsVhvs204a6fXrXhP1HyWyA';
export var owner: string = 'Rimtelecom';
export var mini_owner: string = 'RTC';
export var time_difference = new Date().getTimezoneOffset();
export var lastUpdate: Date = new Date('2024/06/02');
